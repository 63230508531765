import * as React from 'react'
import DefaultLayout from "../layouts/default";
import {Helmet} from "react-helmet";
import {getDefaultTitle} from "../logic/seo";

interface IndexPageProps {
}

export default class IndexPage extends React.Component<IndexPageProps, {}> {
    public render() {
        if (typeof window !== `undefined`) {
            const paperform = require("../../static/js/paperform")
        }
        return (
            <div>
                <Helmet>
                    <title>{getDefaultTitle("Onlineshop zur Aufnahme in unsere Liste melden")}</title>
                    <meta name="description" content="Melden sie uns neue Onlineshops, die Lieferfristen zu Weihnachten haben."/>

                </Helmet>
                <DefaultLayout>
                    <div className="bg-image" style={{
                        backgroundImage: "url(images/christmas.jpg)",
                        backgroundSize: "100%",
                        height: "25vh"
                    }}
                    >
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <h1>Shop melden</h1>
                                <p>Aktuell befinden wir uns noch im Aufbau unserer Plattform und freuen uns über Ihre Vorschläge zu entsprechenden Online-Shops.</p>
                                <div data-paperform-id="w7tbfjif"></div>
                            </div>
                        </div>
                    </div>



                </DefaultLayout>
            </div>

        )
    }
}